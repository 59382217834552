@import 'Variables';

.avatar {
	width: 250px;
	height: 250px;
	object-fit: cover;
	border-radius: 50%;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
	border: $background-primary-light 5px solid;
	margin-bottom: 15px;
}
