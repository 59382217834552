.section {
  text-align: center;
  height: 100%;
  width: 100%;
}

.section-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -99;
  opacity: 1;
  top: 0;
  left: 0;
  pointer-events: none;
}

.section-heading {
  font-family: Georgia, serif;
  line-height: 40px;
  letter-spacing: 5px;
  font-weight: bolder;
  font-size: 45px;
}

.section-heading-border {
  width: 100px;
  height: 4px;
}
