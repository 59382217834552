@import 'Variables';
@import 'HelperMethods';

#navigation-bar {
  .ui.inverted.menu {
    &.labeled.icon {
      width: 100px;
      @include resize(992px, 1199px) {
        width: 80px;
        font-size: 10px;
      }
    }
    &.top.fixed {
      background: transparent;
      position: fixed;
      top: 15px;
      left: 82vw;
      z-index: 1234;
      @include resize(601px, 991px) {
        left: 86vw;
      }
    }
    &.vertical.sidebar .item {
      &.active {
        background: $brand-primary-color;
      }
      @include resize(992px, 1199px) {
        width: 80px;
      }
    }
  }

  .pushable:not(body) {
    transform: none;
  }
  .pushable:not(body) > .ui.sidebar,
  .pushable:not(body) > .fixed,
  .pushable:not(body) > .pusher:after {
    position: fixed;
  }
}
