@import 'Variables';

body,
html {
	margin: 0;
	padding: 0;
	-webkit-animation: fadein 3s; /* Safari, Chrome and Opera > 12.1 */
	-moz-animation: fadein 3s; /* Firefox < 16 */
	-ms-animation: fadein 3s; /* Internet Explorer */
	-o-animation: fadein 3s; /* Opera < 12.1 */
	animation: fadein 3s;
	scroll-behavior: smooth;
}

body,
html,
*,
*:before,
*:after {
	box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-webkit-overflow-scrolling: touch;
	position: relative;
}

@keyframes fadein {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

/* Firefox < 16 */
@-moz-keyframes fadein {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

/* Internet Explorer */
@-ms-keyframes fadein {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

/* Opera < 12.1 */
@-o-keyframes fadein {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

/* Scrollbar */
::-webkit-scrollbar {
	width: 20px;
}

/* Track */
::-webkit-scrollbar-track {
	background: $background-primary-grey;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: $brand-primary-color;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: $brand-primary-color;
}
