@import 'HelperMethods';

#education {
  .row {
    @include resize(992px, 1199px) {
      justify-content: center;
    }
    @include resize(1200px, null) {
      justify-content: space-evenly;
    }
  }
  .education-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    text-align: right;
    &:first-child,
    &:nth-child(2) {
      margin-bottom: 80px;
    }
    .card-title {
      font-weight: bold;
      font-size: 18px;
      margin: 0;
    }
    .card-text {
      font-size: 15px;
      margin: 0;
    }
    .school-year {
      font-style: italic;
    }
    &.red-hexagon {
      border-bottom: 7px solid #ef5145;
      height: 240px;
    }
    &.yellow-hexagon {
      border-bottom: 7px solid #ffc107;
      height: 220px;
    }
    &.blue-hexagon {
      border-bottom: 7px solid #007bff;
      height: 200px;
    }
    @include resize(null, 991px) {
      margin-left: 0;
      width: 100%;
    }
    @include resize(992px, 1199px) {
      width: 110%;
    }
    @include resize(1200px, null) {
      margin-left: 35px;
      &:first-child {
        width: 130%;
      }
      &:nth-child(2) {
        width: 110%;
      }
      &:nth-child(2) {
        width: 90%;
      }
    }
  }
  .hexagon {
    width: 100px;
    height: 55px;
    position: absolute;
    left: -1px;
    top: -29px;
    &:before,
    &:after {
      content: '';
      position: absolute;
      left: 0;
      width: 0;
      height: 0;
      border-left: 50px solid transparent;
      border-right: 50px solid transparent;
    }
    &:before {
      top: -25px;
    }
    &:after {
      bottom: -25px;
    }
    &.red-hexagon {
      background: #ef5145;
      &:before {
        border-bottom: 25px solid #ef5145;
      }
      &:after {
        border-top: 25px solid #ef5145;
      }
    }
    &.yellow-hexagon {
      background: #ffc107;
      &:before {
        border-bottom: 25px solid #ffc107;
      }
      &:after {
        border-top: 25px solid #ffc107;
      }
    }
    &.blue-hexagon {
      background: #007bff;
      &:before {
        border-bottom: 25px solid #007bff;
      }
      &:after {
        border-top: 25px solid #007bff;
      }
    }
  }
}
