@import 'Variables';
@import 'HelperMethods';

#skills {
	#skills-wrapper {
		@include resize(992px, 1400px) {
			padding: 8vw;
		}
	}
	.skill-group {
		margin-bottom: 100px;
		padding-bottom: 30px;
		position: relative;
		.row {
			display: flex;
			justify-content: flex-start;
			// text-align: left;
		}
		.skill-group-title {
			color: $brand-tertiary-color;
			border-radius: 10px;
			font-size: 24px;
			font-weight: bold;
			display: relative;
		}
		&:before,
		&:after,
		.skill-group-title::before,
		.skill-group-title::after {
			content: '';
			display: block;
			position: absolute;
			width: 20%;
			height: 20%;
		}
		&:nth-child(odd) {
			.skill-group-title {
				text-align: left;
				&:after {
					width: 180px;
					border-top: 3px solid $brand-primary-color;
				}
			}
			&:before {
				top: 0;
				right: 0;
				border-top: 5px solid $brand-tertiary-color;
				border-right: 5px solid $brand-tertiary-color;
			}
			&:after {
				bottom: 0;
				left: 0;
				border-bottom: 5px solid $brand-tertiary-color;
				border-left: 5px solid $brand-tertiary-color;
			}
		}
		&:nth-child(even) {
			.skill-group-title {
				text-align: right;
				&:after {
					right: 0;
					width: 180px;
					border-top: 3px solid $brand-primary-color;
				}
			}
			&:before {
				top: 0;
				left: 0;
				border-top: 5px solid $brand-tertiary-color;
				border-left: 5px solid $brand-tertiary-color;
			}
			&:after {
				bottom: 0;
				right: 0;
				border-bottom: 5px solid $brand-tertiary-color;
				border-right: 5px solid $brand-tertiary-color;
			}
		}
	}
	.skill-icon {
		margin: 30px auto;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		align-items: center;

		img {
			width: 75px;
			height: 75px;
			object-fit: contain;
			border-radius: 20px;
		}
		.skill-details {
			visibility: hidden;
			width: fit-content;
			background-color: rgba(10, 104, 218, 0.65);
			color: $brand-primary-color;
			font-weight: bold;
			text-align: center;
			border-radius: 6px;
			padding: 7px 20px;
			position: absolute;
			z-index: 1;
			top: 115%;
			-webkit-transition: all cubic-bezier(0.165, 0.84, 0.44, 1) 0.2s;
			-o-transition: all cubic-bezier(0.165, 0.84, 0.44, 1) 0.2s;
			transition: all cubic-bezier(0.165, 0.84, 0.44, 1) 0.2s;
			-webkit-transform: scale(0);
			-ms-transform: scale(0);
			transform: scale(0);
		}
		&:hover .skill-details {
			visibility: visible;
			-webkit-transform: scale(1);
			-ms-transform: scale(1);
			transform: scale(1);
		}
	}
}
