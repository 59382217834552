@import 'Variables';
@import 'Avatar';
@import 'HelperMethods';

#about {
	.container {
		@include resize(992px, 1199px) {
			padding-left: 3vw;
		}
	}
	.row {
		justify-content: flex-end;
	}
	.info {
		text-align: left;
		border-right: 1px solid $brand-primary-color;
		p {
			color: #fff;
			font-size: 20px;
		}
		.see-more-statement {
			color: grey;
			font-size: 18px;
		}
		button {
			margin: 0 10px;
		}
	}

	.profile-pic {
		padding-left: 50px;
		padding-bottom: 140px;
		@include resize(null, 991px) {
			padding-left: 0;
			padding-bottom: 20px;
			border-left: none;
		}
	}

	.extra-info {
		text-align: left;
		margin: 75px 0;
		p {
			color: #fff;
			font-size: 20px;
		}

		a {
			color: $brand-primary-color;
			font-size: 20px;
			margin-left: 20px;
			&:hover {
				text-decoration: none;
				padding-bottom: 2px;
				border-bottom: 1px solid $brand-primary-color;
			}
		}
		@include resize(992px, 1399px) {
			padding-left: 7vw;
		}
		@include resize(1400px, null) {
			padding-left: 5.5vw;
		}
	}
}
