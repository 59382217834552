@mixin resize($min, $max) {
  @if ($min and $max) {
    @media (min-width: $min) and (max-width: $max) {
      @content;
    }
  } @else if ($min) {
    @media (min-width: $min) {
      @content;
    }
  } @else if ($max) {
    @media (max-width: $max) {
      @content;
    }
  }
}
