$brand-primary-color: #08fdd8;
$brand-secondary-color: #ff4b4b;
$brand-tertiary-color: #ffcc00;
$background-primary-dark: #1b1b1b;
$background-secondary-dark: #252525;
$background-tertiary-dark: #333;
$background-primary-grey: #6c757d;
$background-primary-light-grey: #b5b5b5;
$background-primary-light: #fae8c8;
$heading-primary-font: Georgia;
$heading-secondary-font: serif;
$background-secondary-light: #d5d2cf;

:export {
	brandPrimaryColor: $brand-primary-color;
}
:export {
	brandSecondaryColor: $brand-secondary-color;
}
:export {
	brandTertiaryColor: $brand-tertiary-color;
}
:export {
	backgroundPrimaryDark: $background-primary-dark;
}
:export {
	backgroundSecondaryDark: $background-secondary-dark;
}
:export {
	backgroundTertiaryDark: $background-tertiary-dark;
}
:export {
	backgroundPrimaryGrey: $background-primary-grey;
}
:export {
	backgroundPrimaryLightGrey: $background-primary-light-grey;
}
:export {
	backgroundPrimaryLight: $background-primary-light;
}
:export {
	headingPrimaryFont: $heading-primary-font;
}
:export {
	headingSecondaryFont: $heading-secondary-font;
}
