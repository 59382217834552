@import 'HelperMethods';

#resume {
  .resume-download {
    text-align: center;
    margin-bottom: 20px;
  }
  .container.resume-wrapper {
    position: relative;
    height: 0;
    max-width: 80%;
    padding-bottom: 57%;
    @include resize(null, 991px) {
      max-width: 80%;
      padding-bottom: 90%;
    }
  }
  .iframer {
    position: absolute;
    top: 0;
    left: 20%;
    width: 60%;
    height: 100%;
    border-radius: 10px;
    @include resize(null, 991px) {
      left: 5%;
      width: 90%;
    }
  }
}
