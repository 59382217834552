@import 'Variables';

.elegant-button,
.project-button {
	background: transparent;
	border: 1px solid $brand-primary-color;
	border-radius: 10px;
	color: $brand-primary-color;
	margin: 15px 5px;
	padding: 10px 15px;
	font-size: 18px;
	letter-spacing: 2px;
	// font-family: cursive;
	&:hover {
		text-decoration: none;
		background: $brand-secondary-color;
		color: #fff;
		border: 1px solid $brand-secondary-color;
		box-shadow: 3px 3px 0px 1px rgba(0, 0, 0, 1);
		-webkit-transition: all 0.4s ease-in-out;
		-o-transition: all 0.4s ease-in-out;
		transition: all 0.4s ease-in-out;
	}
	&:focus {
		outline: none;
	}
}
.project-button {
	padding: 4px 10px;
	color: $brand-secondary-color;
	border-width: 2px;
	font-family: cursive;
}
.brand-dark-button {
	display: inline-block;
	cursor: pointer;
	color: $brand-primary-color;
	border: 1px solid $brand-primary-color;
	border-radius: 5px;
	background-color: transparent;
	padding: 5px 15px;
	margin-top: 20px;
	&:hover {
		text-decoration: none;
		color: #fff;
		background-color: $background-primary-grey;
		border: 1px solid $background-primary-grey;
	}
	&:focus {
		outline: none;
	}
}
