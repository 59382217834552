@import 'Variables';
@import 'Button';
@import 'HelperMethods';

#projects-line {
	color: black;
	text-align: left;
	.projects-coming-soon {
		padding-top: 200px;
		color: white;
		text-align: center;
		div {
			position: absolute;
			background: $brand-secondary-color;
			border-radius: 50%;
		}
		div:first-child {
			left: 50%;
			width: 15px;
			height: 15px;
			margin-top: -180px;
			-webkit-transform: translate(-50%);
			-ms-transform: translate(-50%);
			transform: translate(-50%);
			@include resize(null, 991px) {
				left: 25px;
				width: 15px;
				height: 15px;
			}
		}
		div:nth-child(2) {
			left: 50%;
			width: 25px;
			height: 25px;
			margin-top: -120px;
			-webkit-transform: translate(-50%);
			-ms-transform: translate(-50%);
			transform: translate(-50%);
			@include resize(null, 991px) {
				left: 25px;
				width: 25px;
				height: 25px;
			}
		}
		div:nth-child(3) {
			left: 50%;
			width: 35px;
			height: 35px;
			margin-top: -50px;
			-webkit-transform: translate(-50%);
			-ms-transform: translate(-50%);
			transform: translate(-50%);
			@include resize(null, 991px) {
				left: 25px;
				width: 35px;
				height: 35px;
			}
		}
	}
	ul {
		padding-bottom: auto 0 60px 0;
		&.aos-init.aos-animate {
			padding-left: 0;
			padding-right: 0;
		}
		li {
			position: relative;
			width: 6px;
			padding-top: 50px;
			margin: 0 auto;
			background: $brand-primary-color;
			list-style: none;
			@include resize(null, 991px) {
				margin-left: 20px;
			}
			&:first-child {
				padding-top: 20px;
			}
			&:nth-child(odd) .project-card {
				left: 40px;
				-webkit-transform: translate(200px, 0);
				-ms-transform: translate(200px, 0);
				transform: translate(200px, 0);
				-webkit-transition: all 0.2s linear;
				-o-transition: all 0.2s linear;
				transition: all 0.2s linear;
				@include resize(992px, 1060px) {
					width: 375px;
					margin-left: -10px;
				}
				&:before {
					left: -15px;
					border-width: 8px 16px 8px 0;
					border-color: transparent $background-primary-light transparent
						transparent;
				}
			}
			&:nth-child(even) .project-card {
				left: -434px;
				-webkit-transform: translate(-200px, 0);
				-ms-transform: translate(-200px, 0);
				transform: translate(-200px, 0);
				-webkit-transition: all 0.2s linear;
				-o-transition: all 0.2s linear;
				transition: all 0.2s linear;
				@include resize(null, 991px) {
					left: 40px;
				}
				@include resize(992px, 1060px) {
					width: 375px;
					margin-left: 35px;
				}
				&:before {
					right: -15px;
					border-width: 8px 0px 8px 16px;
					border-color: transparent transparent transparent
						$background-primary-light;
					@include resize(null, 991px) {
						left: -15px;
						border-width: 8px 16px 8px 0;
						border-color: transparent #fff transparent transparent;
					}
				}
			}
			&:after {
				content: '';
				position: absolute;
				left: 50%;
				bottom: 0;
				width: 25px;
				height: 25px;
				background: inherit;
				border-radius: 50%;
				-webkit-transform: translateX(-50%);
				-ms-transform: translateX(-50%);
				transform: translateX(-50%);
				-webkit-transition: background 0.5s ease-in-out;
				-o-transition: background 0.5s ease-in-out;
				transition: background 0.5s ease-in-out;
			}
			.project-card {
				position: relative;
				box-shadow: 0px 0px 6px 3px $background-primary-dark;
				bottom: 0;
				text-align: center;
				width: 400px;
				padding: 1rem;
				background: $background-primary-light;
				-webkit-transition: all 0.3s ease-in-out;
				-o-transition: all 0.3s ease-in-out;
				transition: all 0.3s ease-in-out;
				border-radius: 5px;
				@include resize(null, 991px) {
					width: calc(100vw - 90px);
				}
				&:before {
					content: '';
					position: absolute;
					bottom: 5px;
					width: 0;
					height: 0;
					border-style: solid;
				}
				h3 {
					font-weight: bold;
					text-transform: uppercase;
				}
				.project-description {
					text-align: left;
					margin-bottom: 15px;
					img {
						float: left;
					}
				}
				img {
					border-radius: 8px;
					width: 30%;
					padding: 5px;
					margin-right: 10px;
				}
				.project-buttons {
					text-align: right;
					margin-bottom: 10px;
				}
				.project-tools {
					display: flex;
					flex-direction: row;
					flex-wrap: wrap;
					span {
						margin: 2px;
						border-radius: 10px;
						padding: 2px 5px;
						background: transparent;
						border: 2px solid $brand-primary-color;
						color: #000;
					}
					margin-bottom: 15px;
				}
				&:hover {
					bottom: 10px;
					box-shadow: 0px 0px 10px 3px rgb(255, 75, 75);
				}
			}
			&.show .project-card {
				-webkit-transform: none;
				-ms-transform: none;
				transform: none;
				visibility: visible;
				opacity: 1;
			}
			&.show:after {
				background: rgba(233, 75, 75, 1);
			}
		}
	}
}
